<template>
  <div class="add-app">
    <AddComp />
  </div>
</template>

<script>
// @ is an alias to /src
import AddComp from "@/components/AddComp.vue";

export default {
  name: "AddApp",
  components: {
    AddComp,
  },
  mounted() {
    console.log("Add App Page Mounted");
  },
  computed: {},
  watch: {},
};
</script>
