<template>
  <div class="container">
    <form @submit.prevent="submit">
      <div class="input-container">
        <label>App-Name: </label>
        <input v-model="form.name" type="text" />
      </div>
      <small v-if="errors.name">{{ errors.name[0].message }}</small>
      <div class="input-container">
        <label>Server-Host: </label>
        <input v-model="form.server_host" type="text" />
      </div>
      <small v-if="errors.server_host">{{
        errors.server_host[0].message
      }}</small>
      <div class="input-container">
        <label>Auth-Path: </label>
        <input v-model="form.auth_path" type="text" />
      </div>
      <small v-if="errors.auth_path">{{ errors.auth_path[0].message }}</small>
      <div class="input-container">
        <label>Server-Token: </label>
        <input v-model="form.server_token" type="text" />
      </div>
      <small v-if="errors.server_token">{{
        errors.server_token[0].message
      }}</small>
      <div class="input-container">
        <label>Client-Host: </label>
        <input v-model="form.client_host" type="text" />
      </div>
      <small v-if="errors.client_host">{{
        errors.client_host[0].message
      }}</small>
      <button type="submit">Add App</button>
    </form>
  </div>

  <router-view />
</template>

<script>
import FormMixin from "../mixins/FormMixin";
export default {
  name: "AddComp",
  mixins: [FormMixin],
  data: () => ({
    //
  }),
  created() {
    this.setForm({
      name: ["required"],
      server_host: ["required"],
      auth_path: ["required"],
      server_token: ["required"],
      client_host: ["required"],
    });
  },

  methods: {
    async submit() {
      await this.validate();
      console.log(this.formValid);
      console.log(this.errors);
      if (this.formValid) {
        await this.$store.dispatch("apps/setApp", this.form);
        this.clearForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .input-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 6px;
      margin-bottom: 6px;
      label {
        margin-inline-end: 10px;
      }
    }
  }
}
// form > label,
// form > input {
//   margin: 5px;
// }
</style>
